import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTheme } from '../context/ThemeContext';

const ListScreen = () => {
  const { theme } = useTheme();

  return (
    <View style={[styles.screenContainer, theme === 'light' ? styles.lightBackground : styles.darkBackground]}>
      <Text style={[styles.screenText, theme === 'light' ? styles.lightText : styles.darkText]}>Lister</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  lightBackground: {
    backgroundColor: '#f5f5f5',
  },
  darkBackground: {
    backgroundColor: '#333',
  },
  screenText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  lightText: {
    color: '#000',
  },
  darkText: {
    color: '#fff',
  },
});

export default ListScreen;
