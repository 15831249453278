import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, FlatList } from 'react-native';
import { useTheme } from '../context/ThemeContext';
import Icon from 'react-native-vector-icons/Ionicons';
import axios from 'axios';

const DocumentScreen = () => {
  const { theme } = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const styles = getStyles(theme);

  useEffect(() => {
    // Henter filene fra en MySQL-database via et API
    const fetchFiles = async () => {
      try {
        const response = await axios.get('https://sftp.no:3001/api/files');
        const rawFiles = response.data;

        // Bygger opp hierarkisk struktur basert på parent-child forholdet
        const buildFileTree = (files) => {
          const fileMap = {};
          files.forEach(file => {
            file.children = [];
            fileMap[file.id] = file;
          });

          const fileTree = [];
          files.forEach(file => {
            if (file.parent_id) {
              if (fileMap[file.parent_id]) {
                fileMap[file.parent_id].children.push(file);
              }
            } else {
              fileTree.push(file);
            }
          });

          return fileTree;
        };

        setFiles(buildFileTree(rawFiles));
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  const handleFileSelect = (file) => {
    if (file.type === 'file') {
      setSelectedFile(file);
    }
  };

  const toggleFolder = (id) => {
    setExpandedFolders((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderFileItem = (item, level = 0) => (
    <View key={item.id} style={{ marginLeft: level * 10 }}>
      <TouchableOpacity onPress={() => (item.type === 'folder' ? toggleFolder(item.id) : handleFileSelect(item))}>
        <View style={styles.fileItem}>
          <Icon
            name={item.type === 'folder' ? (expandedFolders[item.id] ? 'folder-open' : 'folder') : 'document'}
            size={20}
            color={theme === 'light' ? '#333' : '#ccc'}
          />
          <Text style={styles.fileName}>{item.name}</Text>
        </View>
      </TouchableOpacity>
      {item.type === 'folder' && expandedFolders[item.id] && item.children && (
        <View style={styles.childrenContainer}>
          {item.children.map((child) => renderFileItem(child, level + 1))}
        </View>
      )}
    </View>
  );

  return (
    <View style={styles.screenContainer}>
      {/* Mappestruktur */}
      <View style={styles.sidebar}>
        <Text style={styles.sidebarTitle}>Mappestruktur</Text>
        <ScrollView>{files.map((item) => renderFileItem(item))}</ScrollView>
      </View>

      {/* Forhåndsvisning */}
      <View style={styles.previewContainer}>
        {selectedFile ? (
          <View>
            <Text style={styles.previewTitle}>Forhåndsvisning: {selectedFile.name}</Text>
            <View style={styles.filePreview}>
              <Text>Her vises innholdet i {selectedFile.name}</Text>
            </View>
          </View>
        ) : (
          <Text style={styles.noPreview}>Velg en fil for å se forhåndsvisningen</Text>
        )}
      </View>
    </View>
  );
};

const getStyles = (theme) =>
  StyleSheet.create({
    screenContainer: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: theme === 'light' ? '#f0f0f0' : '#2e2e2e',
    },
    sidebar: {
      width: '30%',
      backgroundColor: theme === 'light' ? '#fff' : '#3a3a3a',
      padding: 10,
    },
    sidebarTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme === 'light' ? '#333' : '#ccc',
      marginBottom: 10,
    },
    fileItem: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      marginBottom: 5,
    },
    fileName: {
      marginLeft: 10,
      color: theme === 'light' ? '#333' : '#ccc',
    },
    childrenContainer: {
      marginLeft: 20,
    },
    previewContainer: {
      flex: 1,
      padding: 10,
    },
    previewTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme === 'light' ? '#333' : '#ccc',
      marginBottom: 10,
    },
    filePreview: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme === 'light' ? '#e0e0e0' : '#444',
      padding: 20,
    },
    noPreview: {
      fontSize: 16,
      color: theme === 'light' ? '#666' : '#aaa',
    },
  });

export default DocumentScreen;