import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { ThemeProvider, useTheme } from './src/context/ThemeContext.js';
import { useLinking } from '@react-navigation/native';

// Importer skjermene
import ToolsScreen from './src/screens/ToolsScreen.js';
import MapScreen from './src/screens/MapScreen.js';
import ListScreen from './src/screens/ListScreen.js';
import SettingsScreen from './src/screens/SettingsScreen.js';
import HomeScreen from './src/screens/HomeScreen.js';
import DocumentScreen from './src/screens/DocumentScreen.js';

// Dropdown-menu-komponent
const DropdownMenu = ({ options, onClose }) => (
  <View style={styles.dropdownContainer}>
    {options.map((option, index) => (
      <TouchableOpacity key={index} onPress={onClose} style={styles.dropdownItem}>
        <Text style={styles.dropdownText}>{option}</Text>
      </TouchableOpacity>
    ))}
  </View>
);

// Topptab-navigasjon
const Tab = createMaterialTopTabNavigator();

function MainTabNavigator() {
  const { theme } = useTheme();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarPosition: 'top',
        tabBarStyle: {
          backgroundColor: theme === 'light' ? '#fff' : '#333',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.8,
          elevation: 4,
        },
        tabBarLabelStyle: { color: theme === 'light' ? '#000' : '#fff', fontWeight: 'bold' },
        tabBarIndicatorStyle: { backgroundColor: '#1e65d6', height: 3 },
        swipeEnabled: false, // Deaktiverer sveiping mellom tabs
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{ title: 'Hjem' }}
      />
      <Tab.Screen name="Documents" component={DocumentScreen} options={{ title: 'Dokumenter' }} />
      <Tab.Screen name="Map" component={MapScreen} options={{ title: 'Kart' }} />
      <Tab.Screen name="List" component={ListScreen} options={{ title: 'Lister' }} />
      <Tab.Screen
        name="Tools"
        component={ToolsScreen}
        options={{ title: 'Verktøy' }}
      />
      <Tab.Screen name="Settings" component={SettingsScreen} options={{ title: 'Innstillinger' }} />
    </Tab.Navigator>
  );
}

// Hovedappen med ThemeProvider og NavigationContainer
export default function App() {
  return (
    <ThemeProvider>
      <NavigationContainer linking={{
        prefixes: ['http://localhost:19006', 'myapp://'],
        config: {
          screens: {
            Home: '/',
            Documents: '/documents',
            Map: '/map',
            List: '/list',
            Settings: '/settings',
            Tools: '/tools',
          },
        },
      }}>
        <MainTabNavigator />
      </NavigationContainer>
    </ThemeProvider>
  );
}

// Stiler for hele applikasjonen
const styles = StyleSheet.create({
  dropdownContainer: {
    position: 'absolute',
    top: 40,
    left: 0,
    right: 0,
    backgroundColor: '#eee',
    padding: 10,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.5,
    elevation: 3,
    zIndex: 1,
  },
  dropdownItem: {
    paddingVertical: 10,
  },
  dropdownText: {
    fontSize: 16,
    color: '#333',
  },
  tabButtonContainer: {
    position: 'relative',
  },
  tabButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  tabButtonText: {
    color: '#00008B',
    fontWeight: 'bold',
  },
});
